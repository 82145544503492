/**
 * This middleware is used to check if the organization exists before try to go to the dedicated page.
 * If the organization exists, we forwarding to a store the linked data to avoid a second Algolia call.
 * If the organizations not exists, we redirecting with the wanted HTTP code to the search page.
 */

import {
  useNuxtApp,
  navigateTo,
  defineNuxtRouteMiddleware,
  useAsyncAction,
  useRequestEvent
} from '#imports'
import { setHeader } from 'h3'
import { callWithNuxt } from 'nuxt/app'

export default defineNuxtRouteMiddleware(async to => {
  // To understand how works this middleware redirection,
  // Read this : https://github.com/nuxt/nuxt/issues/14269

  const nuxtApp = useNuxtApp()

  const { organization } = to.params

  const response = await useAsyncAction(
    'organizations/fetchOrganization',
    organization
  )

  if (response.status.value === 'error') {
    return await callWithNuxt(nuxtApp, () => {
      return navigateTo('/e/recherche?redirect=true', {
        redirectCode: 404
      })
    })
  } else {
    const { updateDate } = response.data.value as any

    if (import.meta.server && updateDate) {
      setHeader(
        useRequestEvent(),
        'last-modified',
        new Date(updateDate).toUTCString()
      )
    }

    // We must link the currentNuxtApp to resolve the nuxt context because
    // we are in a middleware, so we don't have the current nuxt context.
    await callWithNuxt(nuxtApp, async () => {
      return await useAsyncAction('forms/fetchForms', organization)
    })
  }
})
